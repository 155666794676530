<template>
	<div class="product-left">
		<div class="module">
			<h3 class="modtitle">类别</h3>
			<div class="modcontent">
				<div class="box-category">
					<ul>
						<li class="hadchild" v-for="(item, index) in 10" :key="index">
							<a href="#"
								>电脑、手机<i
									class="el-icon-circle-plus-outline"
									@click.stop="categories(index)"
									ref="outline"
								></i
							></a>
							<ul class="category-two" ref="categoryTwo">
								<li v-for="item in 4" :key="item" class="category-two-item">
									<a href="#"
										><i class="el-icon-arrow-right"></i>Men's Watches</a
									>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="product-items">
			<h3 class="modtitle">销量最高</h3>
			<div class="content">
				<div class="shop">
					<div class="shop-left">
						<img src="@/assets/images/f1.jpg" alt="" />
					</div>
					<div class="shop-right">
						<div class="name"><a href="#">平板电脑</a></div>
						<div class="star">
							<ul>
								<li>
									<img src="@/assets/images/icon/星星.svg" alt="" />
								</li>
								<li>
									<img src="@/assets/images/icon/星星.svg" alt="" />
								</li>
								<li>
									<img src="@/assets/images/icon/星星.svg" alt="" />
								</li>
								<li>
									<img src="@/assets/images/icon/星星.svg" alt="" />
								</li>
								<li>
									<img src="@/assets/images/icon/灰星星.svg" alt="" />
								</li>
							</ul>
						</div>
						<div class="money">
							<span class="now-money">$55.00 </span>
							<span class="old-money">$76.00 </span>
						</div>
					</div>
				</div>
				<div class="shop">
					<div class="shop-left">
						<img src="@/assets/images/f1.jpg" alt="" />
					</div>
					<div class="shop-right">
						<div class="name"><a href="#">平板电脑</a></div>
						<div class="star">
							<ul>
								<li>
									<img src="@/assets/images/icon/星星.svg" alt="" />
								</li>
								<li>
									<img src="@/assets/images/icon/星星.svg" alt="" />
								</li>
								<li>
									<img src="@/assets/images/icon/星星.svg" alt="" />
								</li>
								<li>
									<img src="@/assets/images/icon/星星.svg" alt="" />
								</li>
								<li>
									<img src="@/assets/images/icon/灰星星.svg" alt="" />
								</li>
							</ul>
						</div>
						<div class="money">
							<span class="now-money">$55.00 </span>
							<span class="old-money">$76.00 </span>
						</div>
					</div>
				</div>
				<div class="shop">
					<div class="shop-left">
						<img src="@/assets/images/f1.jpg" alt="" />
					</div>
					<div class="shop-right">
						<div class="name"><a href="#">平板电脑</a></div>
						<div class="star">
							<ul>
								<li>
									<img src="@/assets/images/icon/星星.svg" alt="" />
								</li>
								<li>
									<img src="@/assets/images/icon/星星.svg" alt="" />
								</li>
								<li>
									<img src="@/assets/images/icon/星星.svg" alt="" />
								</li>
								<li>
									<img src="@/assets/images/icon/星星.svg" alt="" />
								</li>
								<li>
									<img src="@/assets/images/icon/灰星星.svg" alt="" />
								</li>
							</ul>
						</div>
						<div class="money">
							<span class="now-money">$55.00 </span>
							<span class="old-money">$76.00 </span>
						</div>
					</div>
				</div>
				<div class="shop">
					<div class="shop-left">
						<img src="@/assets/images/f1.jpg" alt="" />
					</div>
					<div class="shop-right">
						<div class="name"><a href="#">平板电脑</a></div>
						<div class="star">
							<ul>
								<li>
									<img src="@/assets/images/icon/星星.svg" alt="" />
								</li>
								<li>
									<img src="@/assets/images/icon/星星.svg" alt="" />
								</li>
								<li>
									<img src="@/assets/images/icon/星星.svg" alt="" />
								</li>
								<li>
									<img src="@/assets/images/icon/星星.svg" alt="" />
								</li>
								<li>
									<img src="@/assets/images/icon/灰星星.svg" alt="" />
								</li>
							</ul>
						</div>
						<div class="money">
							<span class="now-money">$55.00 </span>
							<span class="old-money">$76.00 </span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="product-banner">
			<a href="#">
				<img src="@/assets/images/banner-sidebar.jpg" alt="" />
				<div class="box"></div>
			</a>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			categorys: false,
		}
	},
	methods: {
		categories(index) {
			this.categorys = !this.categorys
			if (this.categorys) {
				this.$refs.outline[index].classList = 'el-icon-remove-outline'
				this.$refs.categoryTwo[index].style.display = 'block'
				return
			} else {
				this.$refs.outline[index].classList = 'el-icon-circle-plus-outline'
				this.$refs.categoryTwo[index].style.display = 'none'
			}
		},
	},
}
</script>
<style scoped lang="scss">
li {
	list-style: none;
}
.product-left {
  font-family: fangsong;
	.module {
		width: 200px;
		border: 1px solid #d7d7d7;
		overflow: hidden;
		margin-bottom: 40px;
		border-radius: 3px;
    background-color: #fff;
		// background-color: yellow;
		.modtitle {
			border-bottom: 1px solid #d7d7d7;
			font-size: 16px;
			color: #222;
			font-weight: 700;
			text-transform: uppercase;
			background-color: #f5f5f5;
			margin: 0;
			padding: 15px;
		}
		.modcontent {
			padding: 15px 0;
			.box-category {
				.hadchild {
					border-bottom: 1px dotted #e4e4e4;
					margin: 0;
					position: relative;
					a {
						color: #333;
						padding: 10px 0;
						display: block;
						font-size: 12px;
            position: relative;
            i {
						font-size: 14px;
            position: absolute;
            top: 12px;
            right: 10px;
					}
					}
					a:hover {
						color: #005ea6;
					}
					.category-two {
						display: none;
						.category-two-item {
							i {
								margin: 0;
								opacity: 0;
                font-size: 12px;
							}
						}
						.category-two-item:hover i {
							opacity: 1;
						}
					}
				}
			}
		}
	}
	.product-items {
		width: 200px;
		border: 1px solid #d7d7d7;
		border-radius: 5px;
		margin-bottom: 40px;
    background-color: #fff;
		.modtitle {
			border-bottom: 1px solid #d7d7d7;
			font-size: 16px;
			color: #222;
			font-weight: 700;
			text-transform: uppercase;
			background-color: #f5f5f5;
			margin: 0;
			padding: 15px;
			margin-bottom: 10px;
		}
		.content {
			.shop {
				width: 100%;
				// background-color: yellow;
				margin-bottom: 10px;
				display: flex;
				.shop-left {
					flex: 1;
				}
				.shop-right {
					flex: 2;
					font-size: 14px;
					.name {
						padding: 0 0 0 15px;
					}
					.star {
						ul {
							display: flex;
							padding-left: 15px;
							margin-bottom: 5px;
							li {
								list-style: none;
								img {
									width: 12px;
									height: 12px;
								}
							}
						}
					}
					.money {
						padding-left: 15px;
						.now-money {
							color: #005ea6;
							font-size: 16px;
							font-weight: 600;
						}
						.old-money {
							text-decoration: line-through;
							color: #aaa;
						}
					}
				}
			}
		}
	}
	.product-banner {
		position: relative;
    img{
      width: 200px;
    }
		.box {
			width: 200px;
			height: 312px;
			background-color: rgba(0, 0, 0, 0);
			position: absolute;
			top: 0;
			margin-left: 5px;
			border-radius: 5px;
			left: -5px;
		}
		.box:hover {
			background-color: rgba(0, 0, 0, 0.3);
		}
	}
}
</style>
